// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: "Ubuntu", sans-serif;
$font-family-heading: "Lato", sans-serif;
$line-height-base: 1.6;
$font-size-base: 0.813rem;

// LAYOUT VARS
$topbar-height: 80px;
$topbar-height-mobile: 70px;

$sidebar-left-width: 120px;
$sidebar-left-width-mobile: 90px;
$sidebar-left-secondary-width: 220px;
$sidebar-left-secondary-width-mobile: 190px;
$side-clearance: 20px;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #d3dae1 !default;
$gray-400: #bac2cb !default;
$gray-500: #808b96 !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #282c30 !default;
$gray-900: #1c2023 !default;
$black: #000 !default;

// Colors
$blue: #3da4db;
$indigo: #7572dd;
$purple: #9561e2;
$pink: #e66fc8;
$light-red: #ff0606;
$red: #bc0303;
$dark-red: #560000;
$orange: #cc6600;
$yellow: #ffcc00;
$gold: #d0a417;
$light-beige: #ffc0c0;
$beige: #ff7e7e;
$brown: #503311;
$green: #009900;
$teal: #9fd7f5;
$cyan: #3b89ff;

$primary: $light-red !default;
$secondary: $red !default;
$tertiary: $dark-red !default;
$quaternary: $beige !default;
$quinary: $light-beige !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$ashen: $gray-300 !default;
$silver: $gray-400 !default;
$graphite: $gray-600 !default;
$deep: $gray-800 !default;
$dark: $gray-900 !default;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: #333333;
$instagram: rgb(193, 53, 132);
$linkedin: rgb(0, 119, 181);
$dribble: #ea4c89;
$youtube: #c4302b;
$whatsapp: #2fb140;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "quaternary": $quaternary,
  "quinary": $quinary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "ashen": $ashen,
  "silver": $silver,
  "graphite": $graphite,
  "deep": $deep,
  "dark": $dark,
  "black": $black,
  "facebook": $facebook,
  "google": $google,
  "twitter": $twitter,
  "instagram": $instagram,
  "linkedin": $linkedin,
  "dribble": $dribble,
  "youtube": $youtube,
  "whatsapp": $whatsapp,
);

a.text-light:hover,
a .text-light:hover {
  color: lighten($tertiary, 10%) !important;
}

a.text-white:hover,
a .text-white:hover {
  color: lighten($tertiary, 10%) !important;
}

.text-xsmall,
.text-xsmall a {
  font-size: 0.6rem !important;
  line-height: 0.8rem;
}

.text-small,
.text-small a {
  font-size: 0.8rem !important;
  line-height: 1.2rem;
}

.text-large,
.text-large a {
  font-size: 1.2rem !important;
  line-height: 1.4rem;
}

.line-0 {
  line-height: 0px;
}

.pointer {
  cursor: pointer !important;
}

.no-pointer {
  cursor: default !important;
}

$fsizes: (
  "0": auto,
  "1": 1.5rem,
  "2": 2rem,
  "3": 2.5rem,
);

@each $fs, $value in $fsizes {
  .line-height-#{$fs} {
    line-height: $value !important;
  }
}

$bwidth: (
  "0": 0px,
  "1": 1px,
  "2": 2px,
  "3": 3px,
  "4": 4px,
  "5": 5px,
);

@each $bw, $value in $bwidth {
  .border-#{$bw} {
    border-width: $value !important;
    border-style: solid !important;
  }

  .border-t-#{$bw} {
    border-top-width: $value !important;
    border-top-style: solid !important;
  }

  .border-s-#{$bw} {
    border-left-width: $value !important;
    border-left-style: solid !important;
  }

  .border-e-#{$bw} {
    border-right-width: $value !important;
    border-right-style: solid !important;
  }

  .border-b-#{$bw} {
    border-bottom-width: $value !important;
    border-bottom-style: solid !important;
  }

  .border-x-#{$bw} {
    border-left-width: $value !important;
    border-left-style: solid !important;
    border-right-width: $value !important;
    border-right-style: solid !important;
  }

  .border-y-#{$bw} {
    border-top-width: $value !important;
    border-top-style: solid !important;
    border-bottom: $value !important;
    border-bottom-style: solid !important;
  }
}

.border-dash {
  border-style: dashed !important;
}

@each $name, $value in $theme-colors {
  .border-#{$name} {
    border-color: $value;
  }

  .bg-h-#{$name}:hover {
    background-color: $value !important;
  }

  .bg-#{$name}-transp {
    background-color: $value + #{77};
  }

  a.link-#{$name},
  a.link-#{$name} * {
    color: $value;
  }

  a.link-#{$name + ":hover"},
  a.link-#{$name + ":hover"} * {
    color: darken($value, 10%);
  }

  a.link-h-#{$name + ":hover"},
  a.link-h-#{$name + ":hover"} * {
    color: $value;
  }
}

$bradius: (
  "0": 0rem,
  "1": 0.5rem,
  "2": 1rem,
);

@each $bw, $value in $bradius {
  .corner-#{$bw} {
    border-radius: $value !important;
  }
  .corner-tl-#{$bw} {
    border-top-left-radius: $value !important;
  }
  .corner-tr-#{$bw} {
    border-top-right-radius: $value !important;
  }
  .corner-bl-#{$bw} {
    border-bottom-left-radius: $value !important;
  }
  .corner-br-#{$bw} {
    border-bottom-right-radius: $value !important;
  }

  .corner-t-#{$bw} {
    border-top-left-radius: $value !important;
    border-top-right-radius: $value !important;
  }
  .corner-r-#{$bw} {
    border-top-right-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }
  .corner-b-#{$bw} {
    border-bottom-left-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }
  .corner-l-#{$bw} {
    border-top-left-radius: $value !important;
    border-bottom-left-radius: $value !important;
  }
}

$deg: (
  "t": 0deg,
  "e": 90deg,
  "b": 180deg,
  "s": 270deg,
);

@each $ind, $val in $deg {
  .bg-gradient-primary-#{$ind} {
    background: linear-gradient(#{$val}, #{lighten($primary, 40%)} 0%, #{$primary} 50%, #{darken($primary, 20%)} 100%);
  }

  .bg-gradient-secondary-#{$ind} {
    background: linear-gradient(
      #{$val},
      #{lighten($secondary, 40%)} 0%,
      #{$secondary} 50%,
      #{darken($secondary, 20%)} 100%
    );
  }

  .bg-gradient-tertiary-#{$ind} {
    background: linear-gradient(#{$val}, #{$quaternary} 0%, #{lighten($tertiary, 20%)} 50%, #{$tertiary} 100%);
  }

  .bg-gradient-quaternary-#{$ind} {
    background: linear-gradient(
      #{$val},
      #{lighten($quaternary, 40%)} 0%,
      #{$quaternary} 50%,
      #{darken($quaternary, 20%)} 100%
    );
  }

  .bg-gradient-quinary-#{$ind} {
    background: linear-gradient(#{$val}, #{lighten($quinary, 40%)} 0%, #{$quinary} 50%, #{darken($quinary, 20%)} 100%);
  }
}

//CKEdior
figure.image {
  img {
    width: 100%;
  }

  figcaption {
    padding: 5px;
    background-color: $gray-200;
    font-size: 11px;
    color: #999;
  }
}

.image-style-align-right {
  float: right;
  margin: 5px 0px 5px 15px;

  figcaption {
    text-align: right;
  }
}

.image-style-align-left {
  float: left;
  margin: 5px 15px 5px 0px;

  figcaption {
    text-align: left;
  }
}

.play.fas,
.play.far {
  opacity: 70%;
  font-size: 57px;
  text-shadow: 2px 2px #000;
  border-width: 6px;
  border-style: solid;
  padding: 0px 22px;
  border-radius: 300px;
  background-color: #000;
  width: 70px;
  height: 70px;
}

a.hover-img {
  img {
    transition: all 0.3s ease-in-out;
    width: 100%;
  }
  img:hover {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    width: 120% !important;
    min-width: 120% !important;
  }
}

a.hover-img {
  img {
    transition: all 0.3s ease-in-out;
    width: 100%;
  }
  img:hover {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    width: 120% !important;
    min-width: 120% !important;
  }
}

.alert-box {
  position: fixed;
  z-index: 100;
  margin: auto;
  top: 65px;
  right: 0px;

  .alert-card {
    right: -500px;
    transition: 0.3s ease-in-out;
    display: none;
  }

  .alert-card.open {
    display: block;
  }
}

.select2-selection__clear {
  width: 22px;
  height: 22px !important;
  background: #f8f9fa !important;
  color: #666;
  padding: 0px;
  margin-top: -11px;
  font-size: 1rem;
  line-height: 0px !important;
  border-radius: 5px;
  border: 1px solid #bac2cb !important;
}

.slideup,
.slidedown {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 0.9s ease-in-out;
  -moz-transition: max-height 0.9s ease-in-out;
  -o-transition: max-height 0.9s ease-in-out;
  transition: max-height 0.9s ease-in-out;
}

.slidedown {
  max-height: 1000px;
}

.text-tiny {
  font-size: 0.7em;
}

.text-small {
  font-size: 0.85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}


/** Select2 */
.select2 {

  width: 100% !important;

  .select2-selection {
    padding: 16px 0px !important;
    min-width: 130px;
    border-radius: 0.375rem;
    border-color: #bac2ca;

    .select2-selection__arrow {
      margin: 4px 0px !important;
    }
    .select2-selection__rendered {
      margin-top: -14px !important;
      margin-right: 10px !important;
    }
  }

  .select2-selection__clear {
    color: #bac2ca;
    height: 25px;
    padding: 0px;
    padding-right: 0px;
    margin-top: -11px;
    font-size: 1rem !important;
    line-height: 0px !important;
  }
  
}

.select2-sm {
  .select2 {
    font-size: 0.7rem;
    
    .select2-selection {
      padding: 10px 0px !important;
      border-radius: 0.25rem;

      .select2-selection__arrow {
        margin: 0px !important;
      }
      .select2-selection__rendered {
        margin-top: -11px !important;
      }
    }

    .select2-selection__clear {
      height: 27px;
    }
  }
}