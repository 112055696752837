// Fonts
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

//Select 2
@import "~select2/dist/css/select2.min.css";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";

// jQuery UI
@import "~jquery-ui/themes/base/all.css";

//Lity
@import "~lity/dist/lity.css";

// Data Ranger Picker
@import "~daterangepicker/daterangepicker.css";

// Datetimepicker
@import "~jquery-datetimepicker/build/jquery.datetimepicker.min.css";

// Datatables
@import "~datatables/media/css/jquery.dataTables.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
@import "~datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css";
@import "~datatables.net-select-bs4/css/select.bootstrap4.min.css";
@import "elements/anchor.scss";

.dataTables_processing {
  top: 7px !important;
  margin: 0px 15px !important;
  width: calc(100% - 30px) !important;
  left: 0px !important;
  padding: 10px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  background: $primary !important;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      $primary 25%,
      $primary 75%,
      rgba(255, 255, 255, 0) 100%) !important;
  color: $white !important;
}

$defColorName: "primary", "secondary", "success", "info", "warning", "danger", "light", "dark", "silver";
$defColor: $primary, $secondary, $success, $info, $warning, $danger, $light, $dark, $silver;

$custom-file-text: (
  en: "Browse",
  pt: "Procurar",
);

.box-shadow {
  box-shadow: 3px 3px 3px #{$dark + "99"};
}

.text-shadow {
  text-shadow: 1px 1px 1px #{$dark + "99"};
}

a,
a:hover {
  text-decoration: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-weight: 400;
}

h1 {
  color: $secondary;
}

h2 {
  color: $primary;
}

h3 {
  color: $quaternary;
}

.fas,
.far {
  font-family: "Font Awesome 5 Free";
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

.h1.fa,
.h1.fas,
.h1.far,
.h2.fa,
.h2.fas,
.h2.far,
.h3.fa,
.h3.fas,
.h3.far,
.h4.fa,
.h4.fas,
.h4.far,
.h5.fa,
.h5.fas,
.h5.far,
.h6.fa,
.h6.fas,
.h6.far {
  font-weight: 900;
}

hr {
  border-top: 1px solid $tertiary;
  border-bottom: 1px solid $quaternary;
  margin-top: 30px;
  margin-bottom: 30px;
}

body {
  background-image: url("/images/bg.png");
  background-size: 100% auto;
  background-repeat: repeat-x;
}

body.minback::before {
  background-color: $light;
  width: 100%;
  height: calc(100% - 200px);
  position: absolute;
  z-index: -1;
  content: "";
  top: 200px;
  left: 0;
}

div[vw] {
  top: 70%;
}

#header {
  .sociais {
    h5 {
      a {
        width: 25px;
        height: 25px;
        display: inline-block;
        font-size: 15px;
        padding: 4px;
        text-align: center;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    #filiados {
      text-align: center;

      br {
        display: inline;
        content: " ";
        clear: none;
      }

      img {
        width: 20%;
        padding-bottom: 0px;
      }
    }

    #contatos {
      text-align: center;
      padding-top: 0px;

      #sociais,
      #telefones {
        h4 {
          font-size: 1.15rem;
          text-align: center;
        }
      }
    }
  }
}

nav {
  font-size: 14px;
}

.bg-none {
  background: transparent;
  background-image: none;
}

.text-editor {

  p,
  ol,
  ul,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding-bottom: 16px;
  }

  h1 {
    padding-bottom: 16px;
  }
}

@include media-breakpoint-up(xs) {
  .h-auto {
    height: auto;
  }
}

.autocomplete-items {
  border: 1px solid $gray-400;
  padding: 2px 5px;
  cursor: pointer;

  div:hover {
    background-color: $gray-200;
  }
}

tfoot {
  font-weight: bold;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "\F105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.cookie-consent {
  border-top: 3px solid $secondary;
  position: fixed;
  z-index: 1000000;
  bottom: 0px;
  background-color: $primary;
  width: 100%;
  padding: 10px 20px;
  color: $light;
  display: flex;
  justify-content: center;

  button {
    background-color: $secondary;
    border: 1px solid $primary;
    border-radius: 5px;
    color: $light;
    padding: 0px 20px;
    margin-left: 40px;
  }

  button:hover {
    background-color: lighten($secondary, 10%);
  }

  a {
    color: $secondary;
  }

  a:hover {
    color: lighten($secondary, 10%);
  }
}

@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {}

@include media-breakpoint-up(lg) {}

@include media-breakpoint-up(xl) {}

/** Ajustes da migração das notícias antigas **/
.alignleft {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: $graphite;

  img {
    display: block;
  }
}

.alignright {
  float: right;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.8rem;
  text-align: right;
  color: $graphite;

  img {
    display: block;
  }
}

.hover-img {
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.hover-img:hover {
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
  width: 120%;
}

.z-index-1 {
  z-index: 1;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.media {
  display: block;
}

input.mask-money {
  text-align: right;
}

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

figure.alignright {
  float: right;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: right;
}

figure.alignleft {
  float: right;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: right;
}

figure.aligncenter {
  padding: 10px 0px;
  display: grid;
}

.gmap {
  iframe {
    width: 100% !important;
    height: 300px !important;
  }
}

.lightbox {
  .modal-dialog {
    max-width: none;

    .modal-body {
      .carousel-inner {
        .ratio {
          background-color: transparent !important;
        }
      }
    }
  }
}

.masonry {

  /* clear fix */
  .grid:after {
    content: "";
    display: block;
    clear: both;
  }

  /* ---- .grid-item ---- */
  .grid-sizer,
  .grid-item {
    width: 33.333%;
    height: 250px;

    @include media-breakpoint-down(sm) {
      height: 350px;
    }

    display: flex;
    justify-content: center;
    overflow: hidden;
    background-color: #eee;
    float: left;
    border: 2px solid #fff;

    img {
      height: 250px;

      @include media-breakpoint-down(sm) {
        height: 350px;
      }

      max-width: none;
      width: auto;
      display: block;
    }
  }
}

//Carrousel Galery
.carousel-gallery {
  .carousel-inner {
    @media (max-width: 767px) {
      .carousel-item>div {
        display: none;
      }

      .carousel-item>div:first-child {
        display: block;
      }
    }

    .carousel-item {
      .card {
        background-color: #eee;
        border: 2px solid #fff;
        border-radius: 0px;

        .card-img {
          overflow: hidden;
          align-items: center;
          justify-content: center;
          display: flex;
          border-radius: 0px;

          a {
            display: inline-block;

            img {
              height: 250px;

              @include media-breakpoint-down(sm) {
                height: 350px;
              }

              width: auto !important;
              max-width: none !important;
            }
          }
        }
      }
    }

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      display: flex;
    }

    /* medium and up screens */
    @media (min-width: 768px) {

      .carousel-item-end.active,
      .carousel-item-next {
        transform: translateX(25%);
      }

      .carousel-item-start.active,
      .carousel-item-prev {
        transform: translateX(-25%);
      }
    }

    .carousel-item-end,
    .carousel-item-start {
      transform: translateX(0);
    }
  }
}

#qrcode {
  margin-right: -120px;

  figure {
    padding: 10px;
    margin: 0px;

    svg {
      width: 100%;
      height: auto;
    }

    figcaption {
      font-weight: 600;
      font-size: 0.6rem;
      padding-top: 5px;
      line-height: 0.8rem;
    }
  }
}

:root {
  --event-tarja: url('/images/events/tarja.png');
  --event-tarja-v: url('/images/events/tarja-v.png');
}

.ingresso {
  .tarja {
    background-image: var(--event-tarja);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
  }

  @include media-breakpoint-down(md) {
    .tarja {
      background-image: var(--event-tarja-v);
      background-size: 100% auto;
    }
  }
}

@media print {
  .ingresso {
    width: 300px !important;
  }

  .badge.bg-secondary {
    -webkit-print-color-adjust: exact;
    background-color: black !important;
    color: white !important;
  }
}