.bg-primary, .bg-primary a, .btn-primary, .btn-primary a,
.bg-secondary, .bg-secondary a, .btn-secondary, .btn-secondary a,
.bg-quinary, .bg-quinary a, .btn-quinary, .btn-quinary a,
.bg-success, .bg-info, .bg-danger, .btn-success, .btn-info, .btn-danger {
  color: $white;
}

.bg-alert, .btn-alert {
  color: $black;
}

.bg-primary a:hover, .btn-primary a:hover,
.bg-secondary a:hover, .btn-secondary a:hover,
.bg-quinary a:hover, .btn-quinary a:hover {
  color: $tertiary;
}

a,
.bg-tertiary, .bg-tertiary a, .btn-tertiary, .btn-tertiary a,
.bg-quaternary, .bg-quaternary a, .btn-quaternary, .btn-quaternary a {
  color: $secondary;
}

a:hover, 
.bg-tertiary a:hover, .btn-tertiary a:hover,
.bg-quaternary a:hover, .btn-quaternary a:hover {
  color: $quinary;
}

a.text-primary:hover,
a.text-secondary:hover,
a.text-tertiary:hover,
a.text-quaternary:hover {
    color: $quinary;
}

a.text-quinary:hover {
    color: $secondary;
}